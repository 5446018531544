// Rich text styling layout
/* stylelint-disable */
.rich-text-area {
    line-height: 1.5;

    img {
        width: 100%;
        height: auto;
        border-radius: $border-radius;
        box-shadow: $shadow-default;
    }

    a:not(.button):not([href*="download?docID"]):not(.textlink) {
        text-decoration: underline;
        font-weight: 600;
        color: map-deep-get($colors, "brand", "secondary");

        &:hover {
            text-decoration: none;
        }
    }

    a[href*="download?docID"] {
        font-weight: 700;
        color: map-deep-get($colors, "white", "default");
        text-decoration: none;
        display: inline-flex;
        align-items: center;

        &::before {
            content: "";
            transition: $transition-default;
            background: url("../images/icons/download-polart.svg");
            background-size: cover;
            height: 1.5rem;
            width: 1.5rem;
            margin-left: 0;
            margin-right: 1rem;
        }

        &:hover {
            text-decoration: none;

            &::before {
                margin-left: 0;
                margin-right: .875rem;
                transform: scale(.9);
            }
        }
    }

    .quote {
        font-weight: 300;
        font-family: $font-family-headings;
        font-style: normal;
        color: map-deep-get($colors, "brand", "tertiary");
    }

    .quote-person {
        font-size: .875rem;
        font-weight: 700;
        color: map-deep-get($colors, "brand", "secondary");
        margin-top: -2rem;
        margin-bottom: 3rem;
    }

    a.textlink {
        font-weight: 700;
        color: map-deep-get($colors, "brand", "secondary");
        text-decoration: none;

        &::after {
            content: "";
            transition: $transition-default;
            background: url("../images/icons/right-polart.svg");
            background-size: cover;
            height: 1.5rem;
            width: 1.5rem;
            margin-right: 0;
            margin-left: 1rem;
            position: relative;
        }

        &:hover {
            text-decoration: none;

            &::after {
                margin-left: 1.25rem;
                margin-right: -.25rem;
            }
        }
    }

    h2, h3, h4, h5, h6 {
        margin-bottom: .5rem;
    }

    h2 {
        font-size: 1.25rem;

        @include media-breakpoint-up(md) {
            font-size: 1.5rem;
        }
    }

    h3 {
        font-size: 1.125rem;

        @include media-breakpoint-up(md) {
            font-size: 1.25rem;
        }
    }

    h4, h5, h6 {
        font-size: 1rem;
    }

    h5 {
        font-weight: 600;
    }

    h6 {
        font-weight: 400;
    }

    ul {
        margin: 1.25rem 0;

        li {
            position: relative;
            padding-left: 1.25rem;
            margin-bottom: 0;

            &::before {
                content: "";
                background: map-deep-get($colors, "brand", "secondary");
                width: 0.375rem;
                height: 0.375rem;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: .5rem;
            }
        }

        ul {
            margin: 0;
        }
    }

    &--dark {
        h1, h2, h3, h4, h5, h6, p {
            color: map-deep-get($colors, "white", "default");
        }

        .quote {
            color: map-deep-get($colors, "brand", "tertiary");
        }

        .button--primary {
            background: map-deep-get($colors, "white", "default");
            color: map-deep-get($colors, "brand", "primary");

            svg {
                fill: map-deep-get($colors, "brand", "primary");
            }

            &:hover {
                color: map-deep-get($colors, "brand", "primary");
            }
        }
    }
}

/* stylelint-enable */