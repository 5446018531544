﻿.hero {
    &__content {
        background: map-deep-get($colors, "brand", "primary");
        border-radius: 0 0 7.5rem;
        padding-bottom: 4rem;

        @include media-breakpoint-up(md) {
            border-radius: 0 0 10rem;
        }

        /* stylelint-disable */
        .button--primary {
            background: map-deep-get($colors, "brand", "secondary");
        }
        /* stylelint-enable */
    }

    &__title {
        color: map-deep-get($colors, "white", "default");

        &::after {
            content: "";
            background-color: #ba17a3;
            display: inline-block;
            position: absolute;
            top: 100%;
            margin-left: .25rem;
            border-radius: 50%;
            height: .375rem;
            width: .375rem;
            transform: translateY(-.625rem);

            @include media-breakpoint-up(sm) {
                height: .5rem;
                width: .5rem;
                transform: translateY(-.875rem);
            }

            @include media-breakpoint-up(md) {
                height: .625rem;
                width: .625rem;
                transform: translateY(-1.125rem);
            }
        }
    }

    &__paragraph {
        color: map-deep-get($colors, "white", "default");
        margin-bottom: 1.5rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 2rem;
        }
    }
}