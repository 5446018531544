﻿.sub-level-1, .sub-level-2, .sub-level-3, .sub-level-4, .sub-level-5, .sub-level-6 {
    &__item {
        @include media-breakpoint-up(md) {
            &:hover {
                &::after {
                    background: url("../images/icons/right-polarttech.svg");
                }
            }

            &--has-sub {
                &::after {
                    background: url("../images/icons/chevron-alt-right-white.svg");
                }

                &:hover {
                    &::after {
                        background: url("../images/icons/right-polarttech.svg");
                    }
                }
            }
        }
    }
}
