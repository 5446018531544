.header {
    &--inverted {
        &::after {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
        }
    }

    &__logo {
        transition: $transition-default;
        width: auto;
        display: block;
        z-index: 1;

        @include media-breakpoint-down(sm) {
            position: fixed;
        }

        svg {
            width: auto;
            height: 1.75rem;
            transition: $transition-default;

            @include media-breakpoint-up(md) {
                height: 2rem;
                position: relative;
            }

            @include media-breakpoint-up(xl) {
                height: 2rem;
            }
        }
    }
}