﻿.search-card {
    &__flex {
        border: solid 1px map-deep-get($colors, "brand", "primary-light");
    }

    &__textlink {
        color: map-deep-get($colors, "brand", "secondary");

        &:hover {
            color: map-deep-get($colors, "brand", "secondary");
        }
    }
}