﻿.article-card {
    $elm: &;

    &__inner {
        &:hover {
            #{$elm}__content {
                &::before {
                    opacity: .65;
                }
            }
        }
    }

    &__textlink {
        color: map-deep-get($colors, "white", "default");

        svg {
            fill: map-deep-get($colors, "brand", "secondary");
        }

        &:hover {
            color: map-deep-get($colors, "white", "default");

            svg {
                fill: map-deep-get($colors, "brand", "secondary");
            }
        }
    }

    &--noimg {
        &#{$elm}--yellow {
            #{$elm}__textlink {
                color: map-deep-get($colors, "brand", "primary");

                svg {
                    fill: map-deep-get($colors, "brand", "secondary");
                }

                &:hover {
                    color: map-deep-get($colors, "brand", "primary");

                    svg {
                        fill: map-deep-get($colors, "brand", "secondary");
                    }
                }
            }
        }

        &#{$elm}--green {
            #{$elm}__textlink {
                svg {
                    fill: map-deep-get($colors, "white", "default");
                }

                &:hover {
                    svg {
                        fill: map-deep-get($colors, "brand", "primary");
                    }
                }
            }
        }
    }
}