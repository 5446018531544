﻿.article {
    &__block {
        border: solid 1px map-deep-get($colors, "brand", "primary-light");
    }

    &__textlink {
        color: map-deep-get($colors, "brand", "secondary");

        &:hover {
            color: map-deep-get($colors, "brand", "secondary");
        }
    }
}

.article-cta {
    background: map-deep-get($colors, "brand", "secondary");
    border-radius: 0 0 7.5rem;

    @include media-breakpoint-up(md) {
        border-radius: 0 0 10rem;
    }

    /* stylelint-disable */
    .button--secondary {
        background: map-deep-get($colors, "brand", "primary");
    }
    /* stylelint-enable */
}