.textlink {
    color: map-deep-get($colors, "brand", "primary");

    svg {
        fill: map-deep-get($colors, "brand", "secondary");
    }

    &--primary {
        svg {
            fill: map-deep-get($colors, "brand", "secondary");
        }
    }

    &--tertiary {
        color: map-deep-get($colors, "brand", "secondary");

        svg {
            fill: map-deep-get($colors, "brand", "secondary");
        }

        &:hover {
            color: map-deep-get($colors, "brand", "secondary");
        }
    }
}