﻿.product-sidebar {
    &__title {
        color: map-deep-get($colors, "white", "default");
    }

    &__textlink {
        svg {
            fill: map-deep-get($colors, "white", "default");
        }

        &:hover {
            color: map-deep-get($colors, "white", "default");
        }
    }
}