﻿.products-home {
    background: map-deep-get($colors, "brand", "primary-medium");

    &__title {
        &::after {
            content: "";
            background-color: #ba17a3;
            display: inline-block;
            position: absolute;
            top: 100%;
            margin-left: .25rem;
            border-radius: 50%;
            height: .375rem;
            width: .375rem;
            transform: translateY(-.625rem);

            @include media-breakpoint-up(sm) {
                height: .5rem;
                width: .5rem;
                transform: translateY(-.875rem);
            }

            @include media-breakpoint-up(md) {
                height: .625rem;
                width: .625rem;
                transform: translateY(-1.125rem);
            }
        }
    }
}