﻿.product-cta {
    border-radius: 0 0 7.5rem;

    @include media-breakpoint-up(md) {
        border-radius: 0 0 10rem;
    }

    &__textlink {
        color: map-deep-get($colors, "white", "default");

        svg {
            fill: map-deep-get($colors, "brand", "secondary");
        }
    }
}